import {
    pageViews,
    ROUTER_PARAMS_VIEW_MAP,
  } from "../utils/util.js";

export function redirect( gridType,router, routerParams, viewName, txnData) {
    // let gridType = tableProps.type;
    const paramsObj = {};
    const filterMapping = ROUTER_PARAMS_VIEW_MAP[gridType];
  
    if (!filterMapping) {
      return {};
    }
  
    for (const [paramKey, filterKey] of Object.entries(filterMapping)) {
      if (viewName !== pageViews.TRASHVIEW) {
        const paramValue = routerParams[paramKey] ? routerParams[paramKey] : txnData[filterKey];
        if (paramValue) {
          paramsObj[paramKey] = paramValue;
        }
      } else {
        paramsObj[paramKey] = "";
      }
    }
    router.push({ name: viewName, params: paramsObj });
  }
  