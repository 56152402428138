<template>
<div v-if="status">
    <IndependentAcceleratorsView />
    <ReportTemplate :table-props="schemaColumns" :project-param-id="projectParamId" :xsd-param-id="xsdParamId"/>
</div>
</template>

<script>
import "vue3-toastify/dist/index.css";
import ReportTemplate from "../components/ReportTemplate.vue";
import IndependentAcceleratorsView from "../views/IndependentAcceleratorsView.vue";
import TBD from "../components/tbd.vue";
import Store from "../vuex/store.js";
import {
    type,
    pageViews,
    app_mode
} from "../utils/util";

export default {
    name: pageViews.SCHEMACONVERTERVIEW,
    components: {
        TBD,
        ReportTemplate,
        IndependentAcceleratorsView,
    },
    methods: {
        setValuesToStore(type) {
            Store.commit("setType", type);
        },
    },
    mounted() {
        this.setValuesToStore(type.SCHEMA);
    },
    data() {
        return {
            status: true,
            /**Upload configs will not be used at the moment */
            uploadConfigs: {
                action: "convert",
                actionLabel: "Convert",
                title: "Convert",
                type: type.SCHEMA,
                accept: ".zip",
            },
            schemaColumns: {
                type: type.SCHEMA,
                isMultiSelectionAllowed: true,
                configs: [{
                        name: "remarks",
                        label: "Remarks",
                        order: 8,
                        isSortable: false,
                        textAlign: "left",
                        textOverflowStyle: "ellipsis",
                    },
                    {
                        name: "lastModifiedDate",
                        label: "Last Modified Date",
                        order: 7,
                        type: "date"
                    },
                    {
                        name: "lineageCount",
                        label: "Lineage Count",
                        order: 6,
                        defaultSelected: true,
                    },
                    {
                        name: "status",
                        label: "Status",
                        order: 5,
                        defaultSelected: true,
                        checked: false,
                       
                    },
                    {
                        name: "xsdName",
                        label: "File Name",
                        order: 4,
                        defaultSelected: true,
                        checked: true,
                        textAlign: "left",
                    },
                    {
                        name: "requestDate",
                        label: "Request Date",
                        order: 1,
                        defaultSelected: true,
                        type: "date",
                    },
                    {
                        name: "xsdId",
                        label: "Schema ID",
                        order: 3,
                    },
                    {
                        name: "projectId",
                        label: "Project ID",
                        order: 2,
                    },
                ],
                actions: {
                    view: {
                        label: 'View Output',
                        popupTitle:'Schema Content'
                    },
                    download: {
                        label: 'Download'
                    }
                },
            },

        }
    },
    props: ["projectParamId", "xsdParamId"],
}
</script>
