<template>
    <footer class="app-footer text-center pt-3">
      <p class="h6">Privacy Policy - Terms of Service - Web Accessibility</p>
      <p class="h6">Copyright ©2025 Psyncopate, Inc. All Rights Reserved. </p>
    </footer>
  </template>
  
  <script>

  import { pageViews } from "@/utils/util";
  export default {
    name: pageViews.FOOTERVIEW,
  };
  </script>
  
  <style scoped>
  .app-footer {
    height: 8vh;
    background-color: #f8f9fa;
    width: 100%;  
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  </style>

