import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
//import './utils/mock'
import ToastService from 'primevue/toastservice';
import CodeDiff from 'v-code-diff'

import 'bootstrap/dist/css/bootstrap.min.css';
import * as bootstrap from "bootstrap";
import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
import axios from 'axios';
async function loadConfigs() {
    const environment = window.acclConfigs.VUE_APP_ENVIRONMENT;

    const payload = {
        props: [
            `common_api_url`,
            `xml_transformation_api_url`,
            `authentication_api_url`,
            `tibco_bucket`,
            `s3_bucket_region`,
            `s3_bucket_access_key`,
            `s3_bucket_access_secret`
        ],
        env: `${environment}`
    };
    const guiSupportUrl = window.acclConfigs.GUI_SUPPORT_URL
    const config = {};
    await axios.post(guiSupportUrl, payload)
    .then(response => {
        const responseData = response.data;  

        for (const [key, value] of Object.entries(responseData)) {
            config[key] = value;
        }
        console.log(config);
    })
    .catch(error => {
        console.error('Error fetching data:', error);
    });
    return config;

}
loadConfigs().then((config) => {
    const app = createApp(App);

    /**Initialize Pinia for state management */
    const pinia = createPinia();
    pinia.use(createPersistedState());

    app.use(CodeDiff)
    app.use(router);
    app.use(ToastService);
    app.use(pinia);



    app.config.globalProperties.$statuses = [
        {
            name: "ok",
            label: "Success"
        },
        {
            name: "in-prog",
            label: "In Progress"
        },
        {
            name: "fail",
            label: "Failed"
        },
        {
            name: "new",
            label: "New"
        }
    ];
    app.config.globalProperties.$acclConfigs = config;
    app.directive('tooltip', {
        mounted(el, binding) {
            el._tooltipInstance = new bootstrap.Tooltip(el, {
                placement: 'top',
                title: binding.value ? binding.value : '',
                customClass: 'custom-tooltip'
            });
            // Listen for the custom event to hide the tooltip
            window.addEventListener('hide-tooltips', () => {
                if (el._tooltipInstance) {
                    el._tooltipInstance.hide();
                }
            });
        },
        updated(el, binding) {
            if (el._tooltipInstance) {
                el._tooltipInstance.setContent({ '.tooltip-inner': binding.value ? binding.value : '' });
            } else {
                el._tooltipInstance = new bootstrap.Tooltip(el, {
                    placement: 'top',
                    title: binding.value ? binding.value : '',
                    trigger: 'hover focus',
                    customClass: 'custom-tooltip'
                });
            }
        },
        unmounted(el) {
            if (el._tooltipInstance) {
                el._tooltipInstance.dispose();
                delete el._tooltipInstance;
            }

            // Remove the event listener
            window.removeEventListener('hide-tooltips', () => {
                if (el._tooltipInstance) {
                    el._tooltipInstance.hide();
                }
            });
        }
    });
    app.mount('#app');
});
