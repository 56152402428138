<template>
    <ReportTemplate ref="reportcomponent" :table-props="userColumns" @editableGridUpdated="onEditableGridUpdate"/>
    <FloatingComponent :changes="changes" :floating-view-configs="floatingViewConfigs" @discardEditableGridChanges="discardChanges" @submitEditableGridChanges="submitChanges" />
</template>

<script>
import ReportTemplate from "@/components/ReportTemplate.vue";
import Store from "../vuex/store.js";
import {
    type,
    pageViews,
    app_mode
} from "../utils/util";
import FloatingComponent from "@/components/FloatingComponent.vue";

export default {
    name: pageViews.MANAGE_USERS_VIEW,
    components: {
        ReportTemplate,
        FloatingComponent,
    },
    methods: {
        setValuesToStore(type) {
            Store.commit("setType", type);
        },
        onEditableGridUpdate(changes) {
            this.changes = changes;
        },
        discardChanges() {
            this.$refs.reportcomponent.discardEditableGridChanges();
            this.changes = {};

        },
        submitChanges() {
            console.log("asdsahjd")
            try{
                this.$refs.reportcomponent.submitEditableGridChanges();
                this.changes = {};
            }catch(error){
                console.error("Error in submitting the changes", error);
            }
        },
    },
    mounted() {
        this.setValuesToStore(type.USER_ROLES);
    },
    data() {
        return {
            status: true,
            floatingViewConfigs: {
                title: "View Updates",
                type: type.USER_ROLES,
            },
            userColumns: {
                type: type.USER_ROLES,
                defaultSortColumn: "lastLoginTime",
                configs: [
                    {
                        name: "lastLoginTime",
                        label: "Last Logged In",
                        order: 1,
                        defaultSelected: true,
                        type: "date",
                    },
                    {
                        name: "userId",
                        label: "User ID",
                        order: 2,
                        defaultSelected: true,
                        textAlign: "left"
                    },
                    {
                        name: "userName",
                        label: "User Name",
                        order: 3,
                        checked: true,
                        defaultSelected: true,
                        textAlign: "left"
                    },
                    {
                        name: "isDisabled",
                        label: "State",
                        order: 5,
                        defaultSelected: true,
                        isEditable: true,
                        dropdownOptions: ["Active", "In-Active"],
                        traceable: false,
                    },
                    {
                        name: "email",
                        label: "Email",
                        order: 4,
                        defaultSelected: true,
                        textAlign: "left"
                    },
                    {
                        name: "idp",
                        label: "SSO",
                        order: 6,
                        defaultSelected: true,
                    },
                    {
                        name: "role",
                        label: "User Role",
                        order: 7,
                        defaultSelected: true,
                        isEditable: true,
                        dropdownOptions: ["user","admin","superadmin"],
                        traceable: true,
                    },
                ],
                
            },
            changes : {},

        }
    },
     
}

</script>