<template>
    <div class="container-fluid mb-0" :style="{ backgroundColor: bgColor }">
        <div class="line row">
            <!-- Left Column -->
            <div class="col-10">
                <AppLink strclass="navbar-brand" name="global-feed"></AppLink>
                <img src="../assets/images/quote-img.png" class="favicon me-2 py-1" />
                <h3>
                    <span :style="{ color: titleColor }" @click="navigateToHome" class="text-effect">
                        {{ title }}
                    </span>
                    <span class="title-medium">{{ updatedTitle }}</span>
                </h3>
            </div>

            <!-- Right Column with Gap -->
            <div v-if="$route.name !== pageView.LOGINVIEW" class="col-2 d-flex justify-content-end align-items-center gap-3 mt-n2 px-1 pb-3">
                <!-- Help Icon -->
                <div @click="redirectToPaveDoc" v-tooltip="supportToolTip">
                    <img src="../assets/icons/help.svg" alt="Help Icon" width="24" height="24"
                        href='https://google.com' />
                </div>
                <!-- Profile Icon Dropdown -->
                <div v-if="!hideProfileIcon">
                    <div class="dropdown">
                        <button class="nav-link" type="button" id="dropdownMenuButton" aria-expanded="false"></button>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                            <li class="logout-list">
                                <a class="dropdown-item d-flex align-items-center" href="#">
                                    <img src="../assets/icons/user.svg" alt="User Logo" class="me-1"/>
                                    {{ userName }}
                                </a>
                            </li>
                            <li class="logout-list">
                                <hr class="dropdown-divider" />
                            </li>
                            <li class="logout-list" v-if="isUserSuperAdmin">
                                <button @click="manageRoles" class="dropdown-item text-danger" type="button">
                                    <img src="../assets/icons/users.svg" alt="Manage Roles Icon"
                                        class="manage-roles-icon" />
                                    User Management
                                </button>
                            </li>
                            <li class="logout-list">                                              
                                <button type="button" class="dropdown-item text-danger" @click="trashView(projectType, userId)">
                                    <img src="../assets/icons/delete-red1.svg" alt="Logout Icon" class="btn-delete"/>
                                    <span class="trash-icon-text"> Trash </span>                                    
                                </button>
                            </li>
                            <li class="logout-list">
                                <button @click="logout" class="dropdown-item text-danger" type="button">
                                    <img src="../assets/icons/logout.svg" alt="Logout Icon" class="logout-icon"/>
                                    Log Off
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import {
    getCookie,
    deleteCookie
} from "@/store/cookie";
import {
    user,
    pageViews,
    role,
    type,
} from "@/utils/util";
import {
    makeAuthenticationRequest,
    getTitle
} from '@/services/apiServices';
import {
    useAuthStore
} from "../store/auth.js";
import { redirect } from "@/utils/routerUtils.js";
import { getSessionItem, setSessionItem } from "@/store/session.js";
import ReportTemplate from "@/components/ReportTemplate.vue";
import { useSortStore, useTableColumnStore } from "../store/sort.js";
import { toast } from "vue3-toastify";
export default {
    name: pageViews.HEADERVIEW,
    components: { ReportTemplate },
    data() {
        return {
            userId: getCookie(user.id),
            projectType: type.PROJECT,
            userName: getCookie(user.name),
            updatedTitle: "",
            isToggled: false,
            newView: "Try New View",
            trashViewToolTip: "Trash",
            supportToolTip: "User Guide",
            pageView : pageViews,
        };
    },
    computed: {
        hideProfileIcon() {
            return this.$route.meta.hideProfileIcon;
        },
        titleColor() {
            return this.$route.meta.titleColor;
        },
        bgColor() {
            return this.$route.meta.bgColor;
        },
        title() {
            return this.$route.meta.title;
        },
        isUserSuperAdmin() {
            return getCookie(user.role) === role.superadmin;
        },
        isNewViewRolledOut() {
            return this.$acclConfigs['rollout-new-view'];
        },
    },
    watch: {
        '$route.params.projectParamId': function (projectId) {
            this.updateTitle(projectId, this.$route.name);
        }
    },
    updated() {
        this.userName = getCookie(user.name);
    },
    routerParams() {
        return this.$route.params;
    },
    methods: {
        redirectToPaveDoc() {
            window.open(
                window.acclConfigs.VUE_APP_SUPPORT_URL,
                '_blank' // <- This is what makes it open in a new window.
            );
            //window.location.href = window.acclConfigs.VUE_APP_SUPPORT_URL;
        },
        setValuesToStore(componentType, userId, requestId, convertedFileName) {
            setSessionItem("userId", userId);
            setSessionItem("componentType", componentType);
        },
        async trashView(type, userId) {
            console.log("Type:", type)
            this.setValuesToStore(type, userId);
            this.redirectView(pageViews.TRASHVIEW, {});
        },
        async redirectView(viewName, txnData) {
            redirect(type.PROJECT, this.$router, this.routerParams, viewName, txnData)
        },
        async logout() {
            const body = {
                userId: getCookie(user.id),
                authSource: getCookie(user.source)
            }
            let toastId = toast.loading("You are logging out", {
                autoClose: false,
                closeOnClick: false,
            });
            try {
                const response = await makeAuthenticationRequest(this.$acclConfigs, "/logout", body)
                if (response.status == 200) {
                    // toast.success('You are logged out', {
                    //     autoClose: 3000,
                    // });

                    toast.update(toastId, {
                        render: "You have been logged out!",
                        type: "success", // Change the toast type
                        isLoading: false,
                        autoClose: 2000, // Set a timeout for the updated toast
                    })
                    deleteCookie(user.id, user.name, user.source);
                    this.clearJWTToken();
                    this.sortStore.clearSortStates();
                    this.tableStore.clearTableStates();
                    this.$router.push({
                        name: pageViews.LOGINVIEW,
                    });
                }
            } catch (error) {
                console.log("Error message: Logout API Failed: ", error.message);
            }

        },
        toggle() {
            this.isToggled = !this.isToggled;
            if (this.isToggled) {
                this.newView = "Switch to Classic View";
                //window.location.href = 'https://google.com';
                this.$router.push({ name: pageViews.VIEW_404 });
            } else {
                this.newView = "Try New View"
                this.$router.push({ name: pageViews.ALLINONE_ACC_VIEW });
            }
        },
        navigateToHome() {
            if (this.isToggled) {
                this.isToggled = !this.isToggled;
                this.newView = "Try New View"
            }
            console.log(this.isToggled);
            this.$router.push({
                name: pageViews.ALLINONE_ACC_VIEW,
            });
        },
        async updateTitle(projectId, routeName) {
            if (![pageViews.ALLINONE_ACC_VIEW, pageViews.TRASHVIEW, pageViews.LOGINVIEW, pageViews.VIEW_404, pageViews.ERROR_VIEW, pageViews.MANAGE_USERS_VIEW].includes(routeName)) {
                try {
                    const response = await getTitle(this.$acclConfigs, projectId, 'project');
                    if (response.status == 200) {
                        let prefix = "-";
                        this.updatedTitle = prefix + ' ' + response.data.title;
                    }
                } catch (error) {
                    console.error('Error making api call - getTitle()', error);
                }
            } else {
                this.updatedTitle = "";
            }
        },
        async manageRoles() {
            this.$router.push({
                name: pageViews.MANAGE_USERS_VIEW,
            });
        },
        setValuesToStore(componentType, userId) {
            setSessionItem("userId", userId);
            setSessionItem("componentType", componentType);
        },
    },
    setup() {
        const sortStore = useSortStore()
        const authStore = useAuthStore();
        const tableStore = useTableColumnStore()

        function clearJWTToken() {
            authStore.clearToken();
        }
        return {
            clearJWTToken,
            sortStore,
            tableStore
        };
    },
};
</script>

<style scoped>
.toggle-icon {
    cursor: pointer;
}

.toggle-icon-img {
    transition: color 0.3s;
}

.dropdown {
    position: relative;
    margin-left: -7px;
}

.manage-roles-icon {
  background-repeat: no-repeat;
  font-size: 12px;
  height: 20px;
  width: 26px;
  border: none;
}

.btn-delete {
  background-repeat: no-repeat;
  font-size: 12px;
  height: 20px;
  width: 26px;
  border: none;
}
.logout-icon {
  background-repeat: no-repeat;
  font-size: 12px;
  height: 20px;
  width: 26px;
  border: none;
  margin-left: -3px;
}
.dropdown-item:hover {
  color: black !important;
}
.dropdown-menu {
    display: none;
    position: absolute;
    /* top: 100%; */
    right: 0;
    min-width: 10rem;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.container-fluid h2 {
    font-family: system-ui;
}

.h3 {
    font-size: medium;
}

.text-effect {
    display: inline-block;
    transition: all 0.1s ease;
    margin-left: -11px;
}

.text-effect:hover {
    text-shadow: 0 0 0.1px #da6a26, 0 0 0.1px #da6a26, 0 0 0 #da6a26;
    cursor: pointer;
    color: #da6a26;
}

.logout-list {
    display: block;
}

.favicon {
    width: 1.3vw;
    float: left;
}

.nav-link {
    background: url("../assets/icons/profile.svg") no-repeat center;
    background-size: cover;
    font-size: 12px;
    margin-top: 3px;
    height: 20px;
    width: 26px;
    border: none;
    padding: 50%;
    padding-right: 20%;
}

.navbar-nav {
    margin-left: auto;
    /* This will align the nav items to the right */
}

nav {
    padding: 1em;
    color: black;
    position: fixed;
    border-radius: 0.25rem;
    position: relative;
    padding: 0.5rem 1rem;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline;
    margin-right: 1em;
}

a {
    color: #050505;
    text-decoration: none;
    font-weight: bold;
}

.title-medium {
    font-size: x-large;
    margin-left: 0.7rem;
}

.manage-roles-icon {
    margin-left: -0.2rem;
}
</style>
