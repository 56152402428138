<template>
  <FileDifMenu :project-param-id="projectParamId" :xsl-param-id="currentRequestId" :xml-param-id="xmlParamId"
    :diff-view="getType" />
  <div class="d-flex vh-75 border" id="split-container">

    <div v-show="isSidebarVisible" class="bg-light border-end ps-3" style="width: 250px; height: 77vh;"
      id="leftBar">
      <div class="row d-flex align-items-center sticky-top"> 
        <h5 class="fw-bold sticky-top p-3 m-0 col">XSLT(s)</h5>
        <button v-show="isSidebarVisible" class="btn btn-light toggle-btn col-2 border-dark  " @click="toggleSidebar" style="z-index: 1;">
            <i :class="isSidebarVisible ? 'fas fa-chevron-left' : 'fas fa-chevron-right'"></i>
          </button>
      </div>
      <ul class="list-group">
        <li v-for="file in files" :key="file.xslFileName" class="file-li list-group-item list-group-item-action text-truncate" style="font-size: 0.8rem; cursor:pointer"
          :class="{ 'active': file.xslFileName === selectedFile.xslFileName }" @click="selectFile(file)">
          {{ file.xslFileName }}
        </li>
      </ul>
    </div>


    <!-- Right Content Area -->
    <div class="flex-grow-1 p-4" id="rightBar">
      <div class="row">
        <div v-if="loading" class="process-loading">
          <div>
            <div class="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- Sidebar Toggle Button -->
          <button v-show="!isSidebarVisible" class="btn btn-light toggle-btn col-1 " @click="toggleSidebar" style="z-index: 1;">
            <i :class="isSidebarVisible ? 'fas fa-chevron-left' : 'fas fa-chevron-right'"></i>
          </button>
          <CodeDiff :old-string="leftFile" :new-string="rightFile" output-format="side-by-side"
            style="height: 75vh; padding:0px" language="XML" :filename="leftFileName" :newFilename="rightFileName"
            class="col" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FileDifMenu from "./XmlandFileDifMenu.vue";
import { getCookie } from "../store/cookie.js";
import { getSessionItem, setSessionItem } from "../store/session.js";
import { type } from "@/utils/util";
import { makeOpearationRequest } from '@/services/apiServices'
import Split from 'split.js';
import { pageViews } from "@/utils/util";

export default {
  name: pageViews.XSL_FILE_DIFF,
  setup() {
    return {
      type,
      makeOpearationRequest
    };
  },
  data() {
    return {
      currentRequestId: 0,
      files: [],
      selectedFile: {},
      loading: true,
      leftFile: "",
      rightFile: "",
      leftFileName: "",
      rightFileName: "",
      isSidebarVisible: true,
    };
  },

  components: {
    FileDifMenu,
  },
  mounted() {
    this.files = this.requestIds
    const sessionSelectedFile = getSessionItem("xslDiffLastSelectedFile")
   this.selectedFile = sessionSelectedFile ? sessionSelectedFile : this.files[0];
    this.currentRequestId = this.files[0].xslId;
    setSessionItem("currentXslId", this.currentRequestId);
    this.invokeShowDifference();
    Split(['#leftBar', '#rightBar'], {
      sizes: [20, 80],
      minSize: [200, 400],
      gutterSize: 3,
      cursor: 'col-resize',
    });
  },
  computed: {
    getType() {
      return type.XSL;
    },
    userId() {
      return getCookie("userId");
    },
    requestIds() {
      return getSessionItem("xslRequestIds");
    },
  },
  methods: {
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    selectFile(file) {
      setSessionItem("xslDiffLastSelectedFile", file);
      this.selectedFile = file;
      this.currentRequestId = file.xslId;
      setSessionItem("currentXslId", this.currentRequestId);
      this.invokeShowDifference();
    },
    invokeShowDifference() {
      this.showDifference().then((data) => {
        this.leftFile = data.inputXsl
        this.leftFileName = 'Source File';
        this.rightFile = data.convertedXsl ? data.convertedXsl : ""
        let rightFileTitle = 'Converted File'
        if ('tiboCount' in data && data.tiboCount == 0) {
          rightFileTitle = 'Converted File - No TIBCO functions in source file'
        }
        this.rightFileName = rightFileTitle;
      });
    },

    async showDifference() {

      var body = {
        userId: this.userId,
        type: type.XSL,
        requestId: this.currentRequestId,
      };

      const endPoint = "/compare"

      try {
        const response = await this.makeOpearationRequest(this.$acclConfigs, endPoint, body)
        console.log("Response", response)
        if (response.status === 200) {
          return response.data;
        } else {
          console.log("Failure in File Comparision :", response.data.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
      finally {
        this.loading = false;
      }
    },
  },
  props: ["projectParamId"]


};
</script>
<style scoped>

.file-li.active{
  background-color: #303a66 !important;
  color: white !important;
  font-weight: bold;
  border-color: none;
}

.file-li:hover{
  background: #da6a26 !important;
  color: #fdfbfb;
}

.toggle-btn {
    width: fit-content;
    margin-right: 0.7rem;

}


/* File Upload Start */
.file-upload-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.file-upload-container button {
  filter: drop-shadow(0 15px 25px rgba(222, 98, 7, 0.15));
  display: inline-block;
  border: none;
  background: #da6a26;
  color: white;
  padding: 15px 40px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  justify-content: center;
}

/* File upload End */
@import "~@fortawesome/fontawesome-free/css/all.min.css";

/* loading spinner Start */
.process-loading {
  height: 68vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.lds-roller,
.lds-roller div,
.lds-roller div:after {
  box-sizing: border-box;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7.2px;
  height: 7.2px;
  border-radius: 50%;
  background: currentColor;
  margin: -3.6px 0 0 -3.6px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 62.62742px;
  left: 62.62742px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 67.71281px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 70.90963px;
  left: 48.28221px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 70.90963px;
  left: 31.71779px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 67.71281px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 62.62742px;
  left: 17.37258px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12.28719px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* loading spinner End */

.fa-3x {
  font-size: 1em;
}

h1 {
  padding-right: 10%;
}
</style>