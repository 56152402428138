<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="report col">
        <div class="d-flex justify-content-end mb-3">
           <!-- New filter button and search form start -->
           <div class="col d-flex gap-2 justify-content-end right-margin">
            <button class="btn btn-primary btn-sm allCategories dropdown-toggle" type="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              <span>{{ searchConcept }}</span>
            </button>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item mt-1" href="#" @click="setSearchFilter('All Categories')">All Categories</a>
              </li>
              <hr class="dropdown-divider" />
              <li v-for="(searchColumns, index) in columns.search" :key="index">
                <a class="dropdown-item mt-1" href="#" @click="setSearchFilter(searchColumns)">{{ searchColumns.label }}</a>
              </li>
            </ul>
            <form class="search-form" @submit.prevent="performSearch">
              <input id="searchBar" class="form-control srchip" v-model="searchQuery" @input="handleInputs"
                type="search" placeholder="" aria-label="Search" />
              <input class="btn btn-outline-dark searchbutton" type="submit" value="Search" @click="searchWithQuery()" />
            </form>
            <button class="clear-filter-icon filter-icon-button" @click="clearFilters">
              <img src="../assets/icons/clear-filter-icon.svg" alt="filter-icon" class="filter-icon" />
            </button>
          </div>
          <!-- New filter button and search form end -->
          <button class="btn-eye" @click="toggleGridVisibility" data-bs-toggle="tooltip"
            title="Toggle Grid Visibility"></button>
          <button @click="downloadData" class="download-button">
            <i class="fas fa-download"></i>
          </button>
          <button type="button" class="btn btn-primary column-button dropdown-toggle" data-bs-toggle="dropdown" :aria-expanded="false">
            Columns
          </button>
          <ul class="dropdown-menu fade">
            <li>
              <a class="dropdown-item" href="#">
                <label>
                  <input type="checkbox" :value="'*'" v-model="selectAll" @change="handleSelectAll" />
                  Select All
                </label>
              </a>
            </li>
            <hr class="dropdown-divider" />
            <li v-for="(column, index) in tableConfigs" :key="index">
              <a class="dropdown-item" href="#">
                <label>
                  <input type="checkbox" :value="column.name" :disabled="column.checked" v-model="selectedColumns"
                    @change="handleColumnChange(column.name)" />
                  {{ column.label }}
                </label>
              </a>
            </li>
          </ul>
        </div>
        <Loading v-if="loading"/>
        <div v-else-if="tableVisible" class="table-responsive full-height">
          <table class="table table-hover table-borderless custom-table">
            <thead>
              <tr>
                <th v-for="(header, index) in selectedConfigs" :key="index" scope="col"
                  class="col align-middle table-header-sticky" @mouseover="hoveredColumn = index"
                  @mouseleave="hoveredColumn = ''" @click="sort(header.name, header.label, index)">
                  <div>
                    {{ header.label }}
                  </div>
                  <div v-if="header.sort && hoveredColumn === index || sortColumn === index" class="sort-buttons">
                    <button class="svg-button" v-if="isDescending">
                      <svg class="svg-icon"
                        style="width: 1em; height: 1em; vertical-align: middle; fill: currentColor; overflow: hidden;"
                        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M573.056 752l308.8-404.608A76.8 76.8 0 0 0 820.736 224H203.232a76.8 76.8 0 0 0-61.056 123.392l308.8 404.608a76.8 76.8 0 0 0 122.08 0z" />
                      </svg>
                    </button>
                    <button class="svg-button" v-if="!isDescending">
                      <svg class="svg-icon"
                        style="width: 1em; height: 1em; vertical-align: middle; fill: currentColor; overflow: hidden;"
                        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M573.056 272l308.8 404.608A76.8 76.8 0 0 1 820.736 800H203.232a76.8 76.8 0 0 1-61.056-123.392L450.976 272a76.8 76.8 0 0 1 122.08 0z" />
                      </svg>
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr v-if="noRecordFound">
                <td class="h4" colspan="15">No records found!</td>
              </tr>
              <tr v-else v-for="(transaction, transactionIndex) in reportTransactions"
                :key="getUniqueIDValue(transaction)">
                <td v-for="column in selectedConfigs" :key="column.name"
                :style="getTextAlignmentStyle(transaction, column)" class="align-middle">
                  <div v-if="shouldTruncate(column.label, getTableData(transaction, column)) && selectAll">
                    {{ truncateText(getTableData(transaction, column), 40) }}
                    <a href="#" @mouseover="showPopover($event, getTableData(transaction, column))" @mouseleave="hidePopover" style="font-size: medium;">...</a>
                  </div>
                  <div v-else>
                    {{ getTableData(transaction, column) }}
                  </div>  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination-bar" v-if="!noRecordFound">
          <Pagination :totalPages="totalPages" :perPage="pageSize" :currentPage="currentPage"
            :totalRecords="totalRecords" @pagechanged="onPageChange" @recordCount="onRecordChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCookie } from "../store/cookie.js";
import { makeOpearationRequest } from "@/services/apiServices";
import Pagination from './Pagination.vue';
import { user } from "../utils/util.js";
import { changeFileExtension } from "@/utils/util.js";
import { toast } from "vue3-toastify";
import { Popover } from 'bootstrap';
import Loading from './Loading.vue';

export default {
  name: "ViewTemplate",
  props: ["tableProps", "projectParamId", "mapperParamId", "defaultSelectedColumns"],
  data() {
    return {
      selectedConfigs: {},
      loading: false,
      tableConfigs: this.tableProps.configs,
      selectedColumns: this.defaultSelectedColumns || [],
      currentPage: 1,
      pageSize: 20,
      totalPages: 5,
      reportTransactions: "",
      totalRecords: 0,
      noRecordFound: false,
      sortColumn: this.getDefaultSortColumnIndex(),
      filterSortColumn: this.tableProps.defaultSortColumn || { name: 'defaultColumn' }, // Def
      filterSortOrder: this.tableProps.defaultSortOrder || { name: 'asc' }, // Default sort order
      selectAll: false,
      tableVisible: true,
      hoveredColumn: "",
      isDescending: true,
      searchConcept: 'All Categories',
      searchQuery: "",
      columns: {
        default: {},
        sort: {},
        search: {}
      },
      showModal: false,
      modalTitle: '',
      modalContent: ''
    };
  },
  inject: ['toggleMapperTabs'],
  components: {
    Pagination,
    Loading
  },
  async mounted() {
    this.refresh();
    this.updateSelectedConfigs();
    this.view();
    this.columns.search = this.tableConfigs
      .filter((item) => item.search != false)
      .map(({ name, label }) => ({ name, label }));
  },
  methods: {
    async view() {
      this.loading = true;
      let searchValues = { queryValue: this.searchQuery.trim() };

      if (this.searchName != "") {
        searchValues = { ...searchValues, ...{ queryColumn: this.searchName } };
      }
      const sortField = (this.tableProps.type !== "mapper.usage" && this.tableProps.type !== "mapper.activities")
          ? (this.filterSortColumn.name.toLowerCase() === 'process' ? 'Process' : `Process,${this.filterSortColumn.name}`)
          : this.filterSortColumn.name;
      let payload = {
        userId: getCookie(user.id),
        type: this.tableProps.type,
        requestId: this.projectParamId,
        mapperId: this.mapperParamId,
        sort: {
          field: sortField,
          order: this.filterSortOrder.name,
        },
        pagination: {
          page: this.currentPage,
          pageSize: this.pageSize,
        },
      };
      if (searchValues != null) {
        payload = { ...payload, ...searchValues };
      }
      try {
        const response = await makeOpearationRequest(this.$acclConfigs, "/view", payload);
        if (response.status === 200) {
          this.reportTransactions = response.data.transactions;
          this.noRecordFound = this.reportTransactions.length === 0;
          this.totalRecords = response.data.totalRecords;
          this.totalPages = Math.ceil(response.data.totalRecords / this.pageSize);
        } else {
          this.noRecordFound = false;
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        this.noRecordFound = true;
      } finally {
        this.loading = false;
      }
    },
    toggleGridVisibility() {
      this.toggleMapperTabs()
    },
    handleSelectAll() {
      if (this.selectAll) {
        this.selectedColumns = this.tableConfigs.map(column => column.name);
      } else {
        this.selectedColumns = [];
      }
      this.updateSelectedConfigs();
      this.view();
    },
    handleColumnChange(value) {
      this.selectAll = this.selectedColumns.length === this.tableConfigs.length;
      this.updateSelectedConfigs();
      this.view();
    },
    updateSelectedConfigs() {
      this.selectedConfigs = this.tableConfigs.filter(column => this.selectedColumns.includes(column.name));
    },
    getUniqueIDValue(transaction) {
      return transaction.id;
    },
    getTextAlignmentStyle(transaction, column) {
	  if (column.textAlign) {
        return `text-align:${column.textAlign}`;
      } else  {
        return `text-align:center`;
      }
      return;
    },
    getStylesForStatus(transaction, column, isActionable) {
      return "";
    },
    getTableData(transaction, column) {
      return transaction[column.label];
    },
    refresh() {
      this.loading = true;
    },
    onPageChange(page) {
      this.currentPage = page;
      this.refresh();
      this.view();
    },
    onRecordChange(recordSize) {
      this.pageSize = recordSize;
      this.refresh();
      this.view();
    },
    async downloadData() {
      let toastId = null;
      let payload = {
        userId: getCookie(user.id),
        type: this.tableProps.type,
        requestIds: [this.projectParamId],
      };
      let config = {
        responseType: "blob",
      };
      try {
        const response = await makeOpearationRequest(this.$acclConfigs, "/download", payload,config);
        if (response.status === 200) {
          // Get the blob from the response
          toast.update(toastId, {
            render: "File downloaded successfully.",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
          const blob = new Blob([response.data], { type: response.data.type });
          // Retrieve the fileName
          let fileName = "downloaded_file";
          const contentDisposition = response.headers['content-disposition'];
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
            if (fileNameMatch.length > 1) {
              fileName = fileNameMatch[1];
            }
          }
          // Create a link element and trigger the download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = changeFileExtension(fileName, "zip");
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

        } else {
          console.error("Failed to fetch data for page:", currentPage);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    },
    convertToCSV(data) {
      const headers = this.selectedConfigs.map(config => config.label).join(',');
      const rows = data.map(row => {
        return this.selectedConfigs.map(config => {
          const value = row[config.label];
          if (typeof value === 'object' && value !== null) {
            return JSON.stringify(value);
          }
          return value;
        }).join(',');
      }).join('\n');
      return `${headers}\n${rows}`;
    },
    sort(name, label, index) {
      if (this.isDescending) {
        this.sortAscending(name, label, index);
      } else {
        this.sortDescending(name, label, index);
      }
    },
    sortAscending(name, label, index) {
      this.sortColumn = index;
      this.isDescending = false;
      this.updateFilterSortOrder("asc", "Ascending");
      this.handleSortByChange(label);
    },
    sortDescending(name, label, index) {
      this.sortColumn = index;
      this.isDescending = true;
      this.updateFilterSortOrder("desc", "Descending");
      this.handleSortByChange(label);
    },
    handleSortByChange(name) {
      this.filterSortColumn.name = name;
      this.view();
    },
    updateFilterSortOrder(name, label) {
      this.filterSortOrder.name = name;
      this.filterSortOrder.label = label;
    },
    getDefaultSortColumnIndex() {
      const defaultSortColumn = this.tableProps.defaultSortColumn;
      if (!defaultSortColumn || !defaultSortColumn.name) {
        return 0;
      }
      const sortColumnName = defaultSortColumn.name;
      const config = this.tableProps.configs.find(item => item.name === sortColumnName);
      return config ? (config.order > 0 ? config.order - 1 : config.order) : 0;
    },
    handleInputs(event) {
      if (!event.target.value) {
        this.view();
      }
    },
    async searchWithQuery() {
      this.currentPage = 1;
      await this.view();
    },
    clearFilters() {
      this.searchName = "";
      this.searchConcept = "All Categories";
      this.searchQuery = "";
      this.view();
    },
    clearUpdatedViews() {
      removeSessionItem(this.tableProps.type + '_columns');
      removeSessionItem(this.tableProps.type + '_view_updated');
    },
    setSearchFilter(filter) {
      if (filter == "All Categories") {
        this.searchConcept = filter;
      } else {
        this.searchConcept = filter.label;
      }
      this.searchName = filter.name;
    },
    showPopover(event, content) {
      const popover = new Popover(event.target, {
        content: content,
        trigger: 'hover',
        placement: 'top',
        container: 'body'
      });
      popover.show();
    },
    hidePopover(event) {
      const popover = Popover.getInstance(event.target);
      if (popover) {
        popover.hide();
      }
    },
    shouldTruncate(label, text) {
      const truncatableLabels = ['Process', 'Process Location', 'Sub Process', 'Sub Process Location', 'Input Node', 'Binding Node', 'Condition', 'Condition Group'];
      return truncatableLabels.includes(label) && text && text.length > 40;
    },
    truncateText(text, length) {
      return text.length > length ? text.substring(0, length) : text;
    },
  },
  
};
</script>

<style scoped>
/* Media Query Start */

/* X-Small devices (portrait phones, less than 576px) */
/* No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
/* @media (min-width: 768px) {
  .filter-label{
    font-size: 15px;
    margin-top: 0px;
  }
 } */

/* Large devices (desktops, 992px and up) */
/* @media (min-width: 992px) { 
  .filter-label{
    font-size: 20px;
  }
 } */

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}

/* Media Query End */

/* Back Button Style Start */

.small-font {
  font-size: 0.7rem;
  margin-bottom: 0;
}

.back-button {
  margin-left: 15%;
  border-radius: 50% !important;
  background-color: rgba(255, 255, 255, 0.2);
  color: black;
  border: none !important;
  /* padding:  30px !important; */
  -webkit-transition: background-color 1s, color 1s, -webkit-transform 0.5s;
  transition: background-color 1s, transform 0.5s;
  box-shadow: 5px 0px 18px 0px rgba(105, 105, 105, 0.8);
}

.back-button:hover {
  -webkit-transform: translateX(-5px);
}

.fa-3x {
  font-size: 2.5rem;
}

.sortbtn:hover,
.sortbtn {
  background-color: white;
  border-color: white;
  /* border-radius: 6px; */
  height: 5vh;
  width: 8vw;
  color: #da6a26;
  text-align: center;
  font-weight: bolder;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  /* margin-left: 1rem; */
  /* margin-right: 12rem; */
}

.sortlbl {
  text-align: center;
  padding-top: 12px;
  padding-right: 3px;
  padding-left: 2px;
  padding-bottom: 8px;
}

.sortbtn.show {
  background-color: white;
  color: #da6a26;
}

.subitbtn {
  background-color: #da6a26;
  color: white;
  border: none;
  border-radius: 7px;
  height: 6vh;
  width: 10vw;
  text-align: center;
  font-weight: bolder;
}

.sort .sortbtn {
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  margin-left: 1rem;
}

.sort {
  width: 22% !important;
  margin-left: 0.8rem;
  background-color: #da6a26;
  color: white;
  border: none;
  align-content: space-evenly;
  border-radius: 7px;
  text-align: left;
  text-justify: left;
  font-weight: bolder;
  /* height: 3vh; */
  /* width: 11vw; */
  float: left;
  padding: 0px 32px 0px 12px !important;
  /* margin-left: -2.5rem; */
}

.label {
  display: none;
  margin-left: 10px;
}

.xml-tranform-sidebar-menu {
  top: 35%;
}

.submitbtn {
  align-content: center;
}

.loading-gif {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 130px;
}

.loading-gif img {
  max-width: 100%;
  /* Ensure the image scales properly */
  max-height: 100%;
  /* Ensure the image scales properly */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.right-margin {
  margin-right: 1rem;
}

.search-form {
  display: flex;
  align-items: center;
}

.col-sel-btn {
  margin-left: 0.2rem;
}

.constabtn {
  margin-left: 1%;
}

.empty {
  margin-right: -11rem;
}

.srch {
  /* margin-left: 5rem;
  margin-right: 6rem; */
}

.clrbtn {
  margin-left: 7rem;
  width: 7vw;
}


.cus-table-header-sticky {
  position: sticky;
  top: 0;
  right: 0;
  z-index: 1;
}

.table-header-sticky {
  height: 0%;
  position: sticky;
  top: 0;
  /* z-index: 1; */
  /* Ensures the header stays on top of other content */
}

/* diff container design start */
.diff-container {
  white-space: normal;
  margin-top: 20px;
}

/* Custom styles to decrease line spacing */
.diff-container .d2h-wrapper .d2h-side-by-side .d2h-code-side-line {
  padding: 2px 10px;
  /* Adjust the padding to reduce space */
}

.diff-container .d2h-wrapper .d2h-side-by-side .d2h-code-line {
  margin: 0;
  /* Remove the default margin */
}

.diff-container .d2h-wrapper .d2h-side-by-side td.d2h-code-side-linenumber {
  padding: 2px 5px;
  /* Adjust the padding of line numbers */
}

/* diff container design end */
.fileName-align {
  text-align: left;
}

.table-group-divider span {
  font-size: 10px;
}

/* table design start */

.custom-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border: 3px solid #ddd;
  border-radius: 8px;
  table-layout: auto;
}

.track-changes-table th {
  background-color: lightgrey;
  color: #000;
}

.custom-table th,
.custom-table td {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.nowrap {
  flex-wrap: nowrap;
  display: inline-flex;
}

.custom-table th {
  background-color: #da6a26;
  color: whitesmoke;
}

.table-responsive {
  position: relative;
  white-space: nowrap;
  font-size: 13px;
  margin-top: -25px;
}

table,
td {
  border: 1px;
}

th {
  border: 1px;
  cursor: pointer;
  position: relative;
}

.xml-tranfomer-dropdown-upload-icon {
  margin-left: 0px;
}

.xml-tranfomer-dropdown-para {
  margin-left: 10px;
}

.btn-view {
  background: url("@/assets/icons/view.svg");
  background-repeat: no-repeat;
  font-size: 12px;
  margin-left: 5px;
  height: 26px;
  width: 26px;
  border: none;
}

.btn-download {
  background: url("../assets/icons/download-blue.svg");
  background-repeat: no-repeat;
  font-size: 12px;
  /* Adjust this size as needed */
  margin-left: 5px;
  /* Space between icon and text */
  height: 26px;
  width: 26px;
  border: none;
}

.btn-show-difference {
  background: url("../assets/icons/compare.svg");
  background-repeat: no-repeat;
  font-size: 12px;
  margin-left: 5px;
  height: 26px;
  width: 26px;
  widows: 30px;
  border: none;
  object-fit: cover;
}

.btn-delete {
  background: url("../assets/icons/delete-red2.svg");

  background-repeat: no-repeat;
  font-size: 12px;
  margin-left: 5px;
  height: 26px;
  width: 26px;
  border: none;
}

.btn-restore {
  background: url("../assets/icons/restorebtn1.svg");

  background-repeat: no-repeat;
  font-size: 12px;
  margin-left: 5px;
  height: 26px;
  width: 26px;
  border: none;
}

.btn-trash {
  background: url("../assets/icons/recycle-icon.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;

  font-size: 10px;
  margin-left: 5px;
  height: 20px;
  width: 20px;
  border: none;
}

.btn-transform {
  background: url("../assets/icons/transform.svg");
  background-repeat: no-repeat;
  font-size: 12px;
  /* Adjust this size as needed */
  margin-left: 5px;
  /* Space between icon and text */
  height: 26px;
  width: 26px;
  widows: 30px;
  border: none;
}

.btn-calculate {
  background: url("../assets/icons/calculator-blue.svg");
  background-repeat: no-repeat;
  font-size: 12px;
  /* Adjust this size as needed */
  margin-left: 5px;
  /* Space between icon and text */
  height: 26px;
  width: 26px;
  border: none;
}

.btn-download:disabled,
.btn-show-difference:disabled,
.btn-delete:disabled,
.btn-restore:disabled,
.btn-trash:disabled,
.btn-transform:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.t .button-group {
  height: 35px;
  float: left;
  margin: 10px;
}

.xml-tranfomer-dropdown {
  background: #666;
  height: 100px;
  width: 300px;
  border: #ccc;
}

.xml-tranfomer-dropdown img {
  height: 30%;
  width: 40%;
}

.container {
  max-width: 95%;
  margin-top: 100px;
}

.file-upload {
  width: 20%;
  height: 58vh;
  border: 2px dashed #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  float: left;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 8vw;
}

.mapper-view .report {
  width: 80%;
  height: 20vh;
  float: left;
}

.report {
  /* background: black; */
  /* border: 2px dashed #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /* transition: all 0.3s ease; */
  width: 80%;
  height: 70vh;
  float: left;
  /* background-color: #FFE8C8; */
}

.report-sub {
  margin-top: 5rem;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  height: 10vh;
  width: 90%;
  border: 2px dashed #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin-left: 5%;
}

.table-custom tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}

.table-custom tbody tr:nth-of-type(even) {
  background-color: #d9d9d9;
}

h1 {
  font-size: 3em;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 0.5em;
  margin-top: 1vh;
}

.h4 {
  font-size: 1.2em;
  font-weight: normal;
  color: #666;
  text-align: center;
  margin-bottom: 1em;
}

.file-upload-sub {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 8vw;
}

.file-upload:hover {
  border-color: #da6a26;
}

.file-upload input[type="file"] {
  display: none;
}

.file-upload button {
  filter: drop-shadow(0 15px 25px rgba(222, 98, 7, 0.15));
  display: inline-block;
  border: none;
  background: #da6a26;
  color: white;
  padding: 15px 40px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  justify-content: center;
}

.file-upload button:disabled {
  background-color: #ccc;
  /* Change this to your preferred disabled color */
  cursor: not-allowed;
}

.file-upload button:hover {
  background-color: #333;
}

.file-upload ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.file-upload li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.file-upload li:hover {
  background-color: #f1f1f1;
}

.file-upload li button[title="Remove"] {
  background-color: #da6a26;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.file-upload li button[title="Remove"]:hover {
  background-color: #da6a26;
}

.file-upload p {
  color: #030a07;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}

nav {
  background-color: #cd7838;
  padding: 1em;
}

ul {
  list-style-type: none;
  padding: 0;
  overflow-y: scroll;
  max-height: 20rem;
}

li {
  /* display: inline; */
  /* margin-right: 1em; */
  margin-top: 1vh;
}

a {
  /* color: white; */
  text-decoration: none;
}

.sticky {
  position: sticky;
  right: 0;
  z-index: 0;
  width: 12%;
  box-shadow: 15px 0 15px -15px #da6a26, -9px 0 8px 0px #a9a9a9;
}

.scroll-class {
  /* height: min-content;
  overflow: scroll; */
  max-height: 150px;
  /* Set your desired max-height */
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 0;
  margin: 10px 0;
  list-style-type: none;
}

.scroll-class li {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
}

.srchip {
    height: 30px;
    width: 10vw !important;
    margin-top: -68px;
}

.searchbutton {
    height: 30px;
    padding: 2px;
    width: 70px;
    margin-top: -65px;
    margin-left: 5px;
}
.divalignleft {
  margin-left: -7rem;
}

.clrdiv {
  margin-left: -0.5rem;
}

.svg-button {
  /* margin-top: -1.5rem !important; */
  float: right;
  align-items: center;
  border: none;
  background-color: #da6a26;
  cursor: pointer;
  color: white;
}

.sort-buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  display: flex;
  gap: 0.5em;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.clear-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2em;
  color: #aaa;
  user-select: none;
}

.clear-button:hover {
  color: #000;
}

.grayed-out {
  color: gray;
  opacity: 0.6;
}

.filter-icon-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clear-filter-icon {
  margin-left: 10px;
  margin-right: 10px;
  border: none;
  background: unset;
  transform: translateX(-20px);
}

.clear-filter-icon:hover .filter-icon {
  filter: brightness(1.5);
  transform: scale(1.2);
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.5));
}

/**Styles for actionable and non actionable columns */
.text-success {
  transition: font-size 0.3s ease;
}

.text-danger {
  transition: font-size 0.3s ease;
}

.text-new-status {
  color: #1d2856;
  transition: font-size 0.3s ease;
}

.text-in-progress {
  color: #cd7838;
  transition: font-size 0.3s ease;
}

.text-not-applicable {
  color: #2b3b78;
  transition: font-size 0.3s ease;
}

.actionable:hover {
  font-size: 1.1em;
  text-decoration: underline;
  cursor: pointer;
}

.dropdown-item {
  line-height: 1.4;
}

.getOverrideCssClass {
  text-align: left;
}

.actions-dropdown-toggle {
  background: url("../assets/icons/menu.svg") no-repeat center center;
  background-size: contain;
  cursor: pointer;
  outline: none;
  transition: background-image 0.3s ease;
  height: 26px;
  width: 26px;
  border: none;
}

.actions-dropdown-toggle.show {
  background-image: url("../assets/icons/menu_open.svg");
  transform: rotate(90deg);
}

.icon-row {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

/* Icon style */
.icon {
  width: 24px;
  height: 24px;
}

.gloss-view-content {
  text-align: center;
  display: grid;
}

.code-editor {
  width: 50%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 16px;
  font-family: monospace;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.code-content {
  white-space: pre-wrap;
  overflow: auto;
  max-height: 300px;
  max-width: fit-content;
  background-color: #dbd8d6;
  color: black;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: inline-block;
}

.toolbar {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
}

.separator {
  height: 2px;
  background-color: #ccc;
  margin: 16px 0;
}

.submit-wrapper {
  margin-left: auto;
}

.submit-button {
  padding: 10px 16px;
  background-color: #da6a26;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #f16915;
}

.discard-button {
  padding: 10px 16px;
  background-color: #dc3545;
  /* Bootstrap danger color */
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.discard-button:hover {
  background-color: #c82333;
  /* Darker red on hover */
}

.editable-icon {
  background-image: url("../assets/icons/editable.svg");
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
}

select {
  border: 1px solid #ccc;
  /* Border color */
  border-radius: 9px;
  /* Rounded corners */
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
}

select:focus {
  outline: none;
  /* Remove outline */
  box-shadow: 0 0 5px rgba(255, 179, 0, 0.676);
  /* Box shadow on focus */
}

.download-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #1D2856 !important;
  margin-right: 5px;
  margin-top: -48px;
  font-size: 20px;
  height: 30px;
}
.download-button:hover {
  filter: brightness(1.5);
  transform: scale(1.2);
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.5));
}
.filter-icon-button{
  color: #1D2856 !important;
  margin-right: -28px;
  margin-top: -65px;
}
.column-button {
  background-color: #1D2856 !important;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-top: -48px;
  margin-right: 10px;
  background-color: #1D2856 !important;
  font-size: 12px;
  height: 30px;
  border-radius: 5px;
}
.allCategories {
  background-color: #1D2856 !important;
  font-size: 12px;
  height: 30px;
  border-radius: 5px;
  margin-top: -48px;
}
.search-form1{
  margin-top: -51px;
  height: 30px;
}

.btn-eye {
  background: url("../assets/icons/eye-close.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 26px;
  border: none;
  cursor: pointer;
  margin-right: 5px;
  margin-top: -45px;
}

.btn-eye:hover {
  filter: brightness(1.5);
  transform: scale(1.2);
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.5));
}

.full-height {
  height: calc(100vh - 150px); /* Adjust the 100px to match the height of your header and footer */
  overflow-y: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 40%;
  width: 100%;
  text-align: center;
  font-size: x-small;
}

</style>
