<template>
    <HeaderView />
    <router-view />
    <FooterView v-if="globalState.showFooter" />
</template>

<script>
import HeaderView from './views/HeaderView.vue';
import FooterView from './views/FooterView.vue';
import { globalState } from './router/router.js'; // Import global state from router.js

export default {
  name: 'App',
  components: {
    HeaderView,
    FooterView
  },
  setup() {
    return { globalState };
  }
};
</script>

<style>
body {
  margin-bottom: 0;
}
</style>
