<template>
  <!-- <MenuBar /> -->
   <div>
    <XmlandFileDif :project-param-id="projectParamId" :xsl-param-id="xslParamId" :xml-param-id="xmlParamId"/>
    <ReportTemplate :table-props="xmlColumns" :project-param-id="projectParamId" :xsl-param-id="xslParamId" :xml-param-id="xmlParamId"/>
    <CanvasView :upload-configs="uploadConfigs" />
  </div>
</template>


<script>
import "vue3-toastify/dist/index.css";
import CanvasView from "../components/CanvasView.vue";
import XmlandFileDif from '../components/XmlandFileDifMenu.vue'
import ReportTemplate from "../components/ReportTemplate.vue";
import Store from '../vuex/store'
import { type } from "../utils/util";
import { pageViews } from "@/utils/util";
export default {
  name: pageViews.XMLTRANSFORM,
  components: {
    ReportTemplate,
    XmlandFileDif,
    CanvasView
  },
  computed: {
    userId() {
      return Store.getters.getUserId;
    },
    requestId() {
      return Store.getters.getRequestId;
    },
  },

  data() {
    return {

      uploadConfigs: {
        action: "transform",
        actionLabel: "Transform",
        title: "Transform",
        type: type.XML,
        accept: ".xml",
      },

      xmlColumns: {
        type: type.XML,
        isMultiSelectionAllowed: true,
        configs: [
          {
            name: "remarks",
            label: "Remarks",
            order: 6,
            isSortable: false
          },
          {
            name: "lastModifiedDate",
            label: "Last Modified Date",
            order: 5,
            type: "date"
          },
          {
            name: "status",
            label: "Status",
            order: 4,
            defaultSelected: true,
            type: 'status',
            search: false,
            isSortable: false,
          },
          {
            name: "requestDate",
            label: "Request Date",
            order: 1,
            defaultSelected: true,
            type: "date"
          },

          {
            name: "xmlFileName",
            label: "File Name",
            order: 3,
            defaultSelected: true,
            textAlign: "left",
          },

          {
            name: "xmlId",
            label: "XML ID",
            order: 2,
          },

        ],
        actions: {
          download: {
            label: 'Download'
          },
          xmlDiff: {
            label: 'Compare'
          },
        },
      },
    };
  },
  props: ["projectParamId", "xslParamId", "xmlParamId"],
};
</script>

