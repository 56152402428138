<template>
  <!-- FileCheck Modal Start -->
  <div class="modal fade" id="fileCheckModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">
            {{ popup.title }}
          </h1>
        </div>
        <div class="modal-body">
          <p>{{ popup.body }}</p>
          <div v-for="file in currentFileNames" :key="file">
            <input type="checkbox" v-model="selectedFileNames" :value="file" checked /> {{ file }}
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="handleSecondaryAction()">
            {{ popup.secondaryActionLabel }}
          </button>
          <button type="button" class="btn btn-danger" @click="handlePrimaryAction()" data-bs-dismiss="modal">
            {{ popup.actionLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- FileCheck Modal End  -->
  <div v-cloak @drop.prevent="addFile" @dragover.prevent class="file-upload" id="file-upload-id" role="dialog">
    <h4>Drag and drop files here or <br />Select Files</h4>

    <!-- File Input Button -->
    <input type="file" multiple @change="handleFiles" ref="fileInput" class="file-input"
      :accept="uploadConfigs.accept" />

    <button @click="triggerFileInput">Select Files</button>

    <p v-if="files.length > 0">{{ files.length }} file(s) selected</p>
    <ul :class="{ 'scroll-class': files.length > 0 }">
      <li v-for="file in files" :key="file.name">
        {{ file.name }} ({{ formatSize(file.size) }} kb)
        <button @click="removeFile(file)" title="Remove" class="btn">X</button>
      </li>
    </ul>
    <div class="widget_text widget-wrap">
      <div class="textwidget custom-html-widget">
        <button :disabled="uploadDisabled || clickedOnce" @click="handleClick" class="btn1">
          {{ uploadConfigs.actionLabel }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { Modal } from "bootstrap";
import { toast } from "vue3-toastify";
import { action,type, user } from "../utils/util";
import  {getCookie} from "../store/cookie.js";
import {getSessionItem, displaySessionStorageItems} from "../store/session.js";
import { makeOpearationRequest,makeXMLTransformRequest } from "@/services/apiServices";
import { useUploadStore } from "../store/fileUpload.js";

export default {
  name: "UploadTemplate",
  computed: {
  },
  data() {
    return {
      requestId: getSessionItem("currentXslId"),
      files: [],
      userId: getCookie(user.id),
      uploadDisabled: true,
      popup: {
      },
      num: 1,

      availableFiles: [],
      currentFileNames: [],
      selectedFiles: [],
      succeededFiles : [],
      failedFiles : [],
      clickedOnce: false,
      selectedFileNames: [],
    };
  },
  props: {
    uploadConfigs: {
      type: Object,
      required: true,
    },
  },

  mounted() {
  },

  methods: {
    showSuccess(message) {
      toast.success(message, {
        autoClose: 3000,
      });
    },
    showError(message) {
      toast.error(message, {
        autoClose: 5000,
      });
    },
    executePrimaryAction() {},

    openModal() {
      const modal = document.getElementById("fileCheckModal");
      document.body.appendChild(modal);

      var myModal = new Modal(modal, {
        keyboard: false,
      });
      myModal.show();
    },
    closeModal() {
      const modal = document.getElementById("fileCheckModal");
      document.body.appendChild(modal);

      var myModal = new Modal(modal, {
        keyboard: false,
      });
      myModal.hide();
    },

    addFile(event) {
      let droppedFiles = Array.from(event.dataTransfer.files);
      if (!droppedFiles) return;
      this.files = this.files.concat(droppedFiles);
    },

    removeFile(file) {
      this.uploadDisabled = true;
      this.files = this.files.filter((f) => f !== file);
    },

    formatSize(size) {
      return (size / 1024).toFixed(2);
    },
    handlePrimaryAction() {
      this.uploadDisabled = false;

      this.files = this.files.concat(
        this.selectedFiles.filter((file) => this.selectedFileNames.includes(file.name))
      );
      this.selectedFileNames = [];
    },
    handleSecondaryAction() {
      this.uploadDisabled = true;
      this.files = this.files.filter(
        (file) => !this.selectedFileNames.includes(file.name)
      );
      this.selectedFileNames = [];
    },
    setConfirmModalData() {
      if (this.availableFiles.length > 0) {
        this.currentFileNames = this.availableFiles.map(file => file.fileName);
        this.selectedFileNames = [...this.currentFileNames]; // Select all files by default
        var fileNameMsg = "The selected files already exist! Would you like to replace them?";
        this.setConfirmModalValues(
          "",
          fileNameMsg,
          "Replace",
          "Cancel"
        );
        this.openModal();
        this.availableFiles = [];
      }
    },
    appendUnAvailFiles(files) {
      const unAvailFiles = this.selectedFiles.filter((selectedFile) =>
        files.some((file) => file === selectedFile.name)
      );

      // Concatenate the filtered files to files
      this.files = this.files.concat(unAvailFiles);
    },
    async handleFiles(event) {
      this.selectedFiles = Array.from(event.target.files);

      if (this.selectedFiles.length >= 0) {
        let fileNames = this.selectedFiles.map((file) => file.name);

        let payload = {
          userId: this.userId,
          files: fileNames,
          type: this.uploadConfigs.type,
        };
        let requestId;
        let apiEndpoint = "";
        if (this.uploadConfigs.type == type.XML) {
          requestId = {
            xslId: this.requestId
          }
          apiEndpoint = "/checkxml";
        } else if(this.uploadConfigs.type == type.PROJECT) {
          requestId = {
            zipId: this.requestId
          }
          apiEndpoint = "/checkfile";
        }
        payload = {...payload, ...requestId}
        let response = null;
        try {
          response = await makeOpearationRequest(this.$acclConfigs, apiEndpoint, payload);

          if (response.status == 200) {
            this.availableFiles = response.data.availableFiles;
            this.appendUnAvailFiles(response.data.unavailableFiles);
            this.setConfirmModalData();
          }
        } catch (error) {
          response = error.response;
          if (error.response.status == 404) {
            this.files = this.files.concat(this.selectedFiles[0]);
          }
        } finally {
        }
      }
      event.target.value = [];
      this.uploadDisabled = false;
    },

    setConfirmModalValues(
      body,
      title,
      actionLabel,
      secondaryActionLabel,
    ) {
      const confirmModalData = {
        body,
        title,
        actionLabel,
        secondaryActionLabel,
      };
      this.popup = { ...confirmModalData };
      
    },
    

    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleClick() {
      this.clickedOnce = true;
      this.process();
    },
    async process() {
      this.isProcessing = true;
      this.uploadDisabled = false;
      
      const id = toast.loading("File is being uploaded. Please wait a moment.");

      const uploadFile = async (file) => {
        try {
          let formData = new FormData();
          formData.append("file", file);
          formData.append("userId", this.userId);
          if (this.uploadConfigs.type === type.XML) {
            formData.append("xslId", this.requestId);
          }

          const response = this.uploadConfigs.type == type.XSL ? await makeOpearationRequest(this.$acclConfigs, '/convert', formData) : await makeXMLTransformRequest(this.$acclConfigs, '/transform', formData);
          if (response.status === 202) {
            const uploadStore = useUploadStore();
            var successMessage = {
              fileName: file.name,
              message: response.data.message
            };
            this.succeededFiles.push(successMessage);
            this.removeFile(file);
            uploadStore.setUploadComplete(true);
            return successMessage;
          }
        } catch (error) {
          var failureMessage = {
            fileName: file.name,
            message: error
          };
          this.failedFiles.push(failureMessage);
          return failureMessage;
        }
      };

      const uploadPromises = Array.from(this.files).map((file) =>
        uploadFile(file)
      );

      Promise.all(uploadPromises)
        .then(() => {
          if (this.succeededFiles.length != 0) {
            setTimeout(() => {
              toast.update(id, {
                render: this.succeededFiles.length + " File(s) Accepted",
                autoClose: true,
                closeOnClick: true,
                closeButton: true,
                type: 'success',
                isLoading: false,
              });
              this.succeededFiles = [];
            });
          }
          if (this.failedFiles.length != 0) {
            setTimeout(() => {
              toast.update(id, {
                render: this.failedFiles.length + " File(s) Failed",
                autoClose: true,
                closeOnClick: true,
                closeButton: true,
                type: 'error',
                isLoading: false,
              });
              this.failedFiles = [];
            });
          }
        })
        .catch((error) => {
        })
        .finally(() => {
          this.isProcessing = false; // Re-enable the button
          this.clickedOnce = false; // Reset clickedOnce
        });
    },
  
    report() {
      this.$emit("report");
    },
  },
};
</script>

<style>
.btn1:disabled{
  cursor:not-allowed;
}
.file-upload {
  width: 100%;
  height: 58vh;
  border: 2px dashed #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  float: left;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 8vw;
}

.file-upload-sub {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 8vw;
}

.file-upload:hover {
  border-color: #da6a26;
}

.file-upload input[type="file"] {
  display: none;
}

.file-upload button {
  filter: drop-shadow(0 15px 25px rgba(222, 98, 7, 0.15));
  display: inline-block;
  border: none;
  background: #da6a26;
  color: white;
  padding: 15px 40px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  justify-content: center;
}

.file-upload button:disabled {
  background-color: #ccc;
  /* Change this to your preferred disabled color */
  cursor: not-allowed;
}

.file-upload button:hover {
  background-color: #333;
}

.file-upload ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.file-upload li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.file-upload li:hover {
  background-color: #f1f1f1;
}

.file-upload li button[title="Remove"] {
  background-color: #da6a26;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.file-upload li button[title="Remove"]:hover {
  background-color: #da6a26;
}

.file-upload p {
  color: #030a07;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}

.scroll-class {
  max-height: 150px;
  /* Set your desired max-height */
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 0;
  margin: 10px 0;
  list-style-type: none;
}

.scroll-class li {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
}

h4 {
  font-size: 1.2em;
  font-weight: normal;
  color: #666;
  text-align: center;
  margin-bottom: 1em;
}
.btn1:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>