<template>
  <div class="popup-overlay" @click="closePopup">
    <div class="popup-content" @click.stop>
      <div class="d-flex align-items-center justify-content-between">
        <strong class="m-0 mx-auto">{{ popupTitle }} </strong>
        <button @click="closePopup" class="close-button btn" type="button">&times;</button>
      </div>

      <div class="row content-container">
        <div class="file-sidebar mt-0" :class="{ 'collapsed': isCollapsed }" id="left-bar" v-show="!isCollapsed"
          style="width: 250px; height: 66vh;margin-left: 0.5rem;">
          <div class="d-flex justify-content-between align-items-center ">
            <h5 class="fw-bold sticky-top ps-2 mt-0 col " style="font-size: small;">Schema(s)</h5>
            <button @click="toggleSidebar" class="toggle-btn btn border-0 pt-0 bg-transparent" v-show="toggleButton">
              <i :class="isCollapsed ? 'fas fa-chevron-right' : 'fas fa-chevron-left'"></i>
            </button>
          </div>
          <ul class="file-list" v-show="!isCollapsed">
            <li v-for="(file, index) in fileData.files" :key="index" class="file-item">
              <button v-if="file != null" @click="selectFile(file)"
                class="btn btn1 d-flex align-items-center w-100 border-0 text-start bg-transparent p-2"
                :class="{ 'active': file.fileName === selectedFile }">
                <span class="file-icon">📄</span> <!-- Use an icon library like FontAwesome -->
                <span class="file-name">{{ file.fileName }}</span>
              </button>
            </li>
          </ul>
        </div>

        <!-- Right Section with Dynamic Width -->
        <div class="row right-section" :class="{ 'expanded': isCollapsed }" id="right-bar">


          <button @click="toggleSidebar" class="col-1 btn border-0 bg-transparent toggle-btn"
            v-if="isCollapsed && toggleButton">
            <i :class="isCollapsed ? 'fas fa-chevron-right' : 'fas fa-chevron-left'"></i>
          </button>

          <div class="sticky-header col">
            <div class="tab-buttons">
              <button v-for="(data, index) in popup.viewContent" :key="index" @click="selectedTab = index"
                :class="{ active: selectedTab === index }"> {{ data.title }}</button>
            </div>
            <Loading v-if="loading" />

            <div class="tab-content" v-else>
              <div v-for="(data, index) in popup.viewContent" :key="index">
                <div v-if="data.title === 'Lineage' && selectedTab === 0">
                  <TreeItem :data="data.content" />
                </div>

                <div
                  v-else-if="data.title === 'Avro Schema' && selectedTab === 3 && data.content && Object.keys(data.content).length === 0"
                  class="text-center">
                  <strong class="ps-5 text-danger fw-bolder">No Records Found</strong>
                </div>

                <div v-else>
                  <pre class="nowrap"
                    v-if="selectedTab === index"><code :class="`language-${data.format}`">{{ data.content }}</code></pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading.vue';
import TreeItem from './TreeItem.vue';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css';
import { registerHljsLineNumbers, injectHljsLineNumbersCss } from '../utils/highlight-line-numbers-plugin.js';
import Split from 'split.js';
import { type } from "../utils/util.js";

export default {

  props: {
    projectName: {
      type: String,
      required: true
    },
    setShowPop: {
      type: Function,
      required: true
    },
    popup: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    fileData: {
      type: Array,
      required: true
    }
  },
  computed: {
    popupTitle() {
      const projectId = this.fileData.projectId;
      const projectName = this.projectName;

      if (this.projectName) {
        this.storedProjectName = projectName;
        this.lastProjectId = projectId;
        return `${this.fileData.popupTitle} - ${this.storedProjectName}`;
      }
      return `${this.fileData.popupTitle} - `;
    },

  },
  components: {
    TreeItem,
    Loading
  },
  methods: {
    selectFile(file) {
      this.selectedFile = file.fileName;
      this.$emit('popupRequestId', file, this.fileData.userId);
    },
    closePopup() {
      this.setShowPop(false);
    },
    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed;
    },
    highlightCode() {
      this.$nextTick(() => {
        hljs.highlightAll();
        document.querySelectorAll("pre code").forEach((block) => {
          hljs.lineNumbersBlock(block);
        });
      });
    }
  },
  data() {
    return {
      toggleButton: false,
      selectedTab: 0,
      selectedFile: null,
      format: "yaml",
      textWrapping: false,
      storedProjectName: "",
      lastProjectId: " ",
      isCollapsed: true,
    }
  },
  watch: {
    selectedTab() {
      this.$nextTick(() => {
        this.highlightCode();
      });
    },
    "popup.viewContent": {
      handler() {
        this.highlightCode();
      },
      deep: true,
    },
  },
  mounted() {
    window.dispatchEvent(new Event('hide-tooltips'));
    registerHljsLineNumbers(hljs);
    injectHljsLineNumbersCss();
    this.highlightCode();
    //storing project name and project id in varibles
    if (this.projectName) {
      this.storedProjectName = this.projectName;
      this.lastProjectId = this.fileData.projectId;
    }
    if (this.fileData.fileType === type.SCHEMA) {
      this.isCollapsed = false;
      this.toggleButton = true;
    }
    this.selectFile(this.fileData.files[0], this.popupCommanData)
    Split(['#left-bar', '#right-bar'], {
      sizes: [20, 80],
      minSize: [200, 400],
      gutterSize: 1,
      cursor: 'col-resize',
    });

  },
};
</script>

<style scoped>
.no-records {
  text-align: center;
  color: red;
  padding: 5%;
}

.btn1.active {
  background-color: #303a66 !important;
  color: white !important;
  font-weight: bold;
}

.file-sidebar {
  background-color: #f8f9fa;
  color: #0a0909;
  font-weight: bolder;
  height: 80%;
  max-height: 66vh;
  width: 250px;
  /* padding: 6px; */
  border-right: 1px solid #333;
  overflow-y: auto;
  margin-left: 0.5rem;
}

.file-sidebar.collapsed {
  width: 40px;
  overflow: hidden;
  margin-left: 1rem;
}

.btn1:hover {
  background: #2a3669 !important;
  color: #fdfbfb;
}

.file-icon {
  margin-right: 8px;
}

.file-name {
  flex-grow: 1;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0px;
}

.file-list li {
  size: 1rem;
  margin-bottom: 6px;
  text-align: left;
}

.content-container {
  display: flex;
  flex: 1;
  overflow: hidden;
  /* --bs-gutter-x: 0.9rem !important; */
  --bs-gutter-x: 14px !important;
}

.right-section {
  padding-left: 0rem !important;
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  width: 70%;
  margin-left: o.5rem;
}

.strong {
  font-size: larger;
}

.nowrap {
  white-space: pre !important;
  overflow: unset !important;
}

.tab-buttons {
  display: flex;
}

.tab-content {
  overflow: auto !important;
  max-height: calc(77vh - 100px);
  height: 90%;
  background: #F3F3F3;
  text-align: left;
  margin-top: 1%;
  width: 100%;
  padding: 10px;
}

.tab-buttons button {
  padding: 1px 3px;
  cursor: pointer;
  border: none;
  background-color: #ddd;
  border-radius: 5px;
  font-size: smaller;
  font-weight: bold;
  margin-right: 3px;
}

.tab-buttons button.active {
  background-color: #ddd;
  border-bottom: 3px solid #da6a26;
  border-radius: 5px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.popup-content {
  background: white;
  padding: 5px;
  padding-top: 5px;
  border-radius: 8px;
  width: 100%;
  max-width: 80rem;
  height: 80%;
  max-height: 80vh;
  position: relative;
  text-align: left;
  border-style: ridge;
}

pre {
  margin: 0;
  margin-right: 0.7rem;
  padding: 1rem;
  color: #333;
  background: #f5f5f5;
  font-family: monospace;
  white-space: pre-wrap;
  text-align: left;
}

pre code.hljs {
  overflow-x: unset !important;
}

button.close-button {
  background: transparent;
  color: red;
  border: none;
  font-size: 1.3rem;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
}

button.close-button:hover {
  color: black;
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
  margin-left: 0.25rem;
  width: 99%;
  padding-left: 0rem !important;
}

.fileName {
  padding-bottom: 0.3rem;
  flex: 1;
  font-weight: bold;
  max-width: 100%;
  overflow-wrap: break-word;
  white-space: normal;

}

.toggle-btn {
  /* position: relative; */
  z-index: 1;
  height: fit-content;
  width: fit-content;
  margin-right: 0.3rem;

}
</style>
<style>
.hljs-ln-numbers {
  text-align: center;
  color: rgb(31 35 40 / 38%);
  border-right: 1px solid #ddd;
  padding-right: 10px !important;
  background: #f5f5f5;
  vertical-align: top;
  /* Align to top if necessary */
  white-space: nowrap;
  /* Prevent line wrapping */
}

/* for block of code */
.hljs-ln-code {
  line-height: 0%;
  position: relative;
  left: -55px;

}

.hljs-ln-n {
  /* Styling for the line numbers */
  display: block;
  line-height: 1.5;
  /* Adjust as needed */
  padding: 0;
  /* Remove extra padding */
}

.hljs-attr {
  /* Styling for attributes */
  display: inline;
  /* Ensure no extra space is added */
  padding: 0;
  /* Remove extra padding */
}
</style>