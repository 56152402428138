<template>
    <div class="container-fluid mb-1">
        <div class="row">
            <div class="col-1">
                <button class="btn back-button" @click="this.$router.back()">
                    <span><i class="fa fa-arrow-left fa-3x"></i></span>
                </button>
            </div>
            <div class="col d-flex justify-content-center align-items-center">
                <p class="h4">Trash</p>
            </div>
            <div class="col-1"></div>
        </div>
    
    </div>
    <ReportTemplate :table-props="tableColumns" />
    </template>
    
    <script>
    import "vue3-toastify/dist/index.css";
    import ReportTemplate from "../components/ReportTemplate.vue";
    import {
        getSessionItem
    } from "@/store/session";
    
    import {
        type
    } from "../utils/util";
    export default {
        name: "TrashView",
    data() {
        return {
            tableColumns: {
                        type: type.PROJECT,
                        isMultiSelectionAllowed: false,
                        filters: [{
                            isDeleted: true
                        }],
                        configs: [{
                            name: "remarks",
                            label: "Remarks",
                        order: 7,
                            isSortable: false,
                            textAlign: "left",
                        },
                        {
                            name: "accelatorStatus",
                            label: "Accelerator Status",
                                order: 5,
                            isSortable: false,
                                defaultSelected: true,
                            computedValue: "",
                            search: false,
                        },
                        {
                            name: "lastModifiedDate",
                            label: "Last Modified Date",
                                order: 6,
                            type: "date",
                        },
                        {
                            name: "status",
                            label: "Status",
                            order: 4,
                            defaultSelected: true,
                            isSortable: false,
                        },
                        {
                            name: "fileName",
                            label: "File Name",
                            order: 3,
                            defaultSelected: true,
                            checked: true,
                            textAlign: "left",
                        },
                        {
                            name: "requestDate",
                            label: "Request Date",
                            order: 1,
                            defaultSelected: true,
                            type: "date",
                        },
                        {
                            name: "projectId",
                            label: "Project ID",
                            order: 2,
                        },
                        ],
                        actions: {
                            restore: {
                                label: 'Restore'
                            }
                        },
                        },
            };
        },
        components: {
            ReportTemplate,
        },
        mounted() {
        },
        computed: {
        }
    };
    </script>
    
    <style>
    .back-button {
        border-radius: 50% !important;
        background-color: rgba(255, 255, 255, 0.2);
        color: black;
        border: none !important;
        /* padding:  30px !important; */
        -webkit-transition: background-color 1s, color 1s, -webkit-transform 0.5s;
        transition: background-color 1s ease, transform 0.5s ease, text-shadow 0.1s ease;
        box-shadow: 5px 0px 18px 0px rgba(105, 105, 105, 0.8);
    }
    
    .back-button:hover {
        -webkit-transform: translateX(-5px);
        text-shadow: 1px 1px 1px #000, 0 0 1px #000, 0 0 1px #000;
    }
    
    .fa-3x {
        font-size: 1.5rem;
    }
    </style>
    
